<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :loading="Object.entries(data).length === 0"
    loading-text="Chargement des données. Veuillez patienter..."
    hide-default-footer
    class="elevation-0"
  >
    <template v-slot:item.dep="{ item }">
      {{ item.dep }} ({{item.num}}) 
    </template>
    
    <template v-slot:item.count="{ item }">
      <v-chip :color="getColor(item.count)" dark>
        {{ item.count }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['data'],

  data() {
    return {
      sortBy: 'count',
      sortDesc: true,
      headers: [
        {
          text: 'Nom',
          align: 'start',
          value: 'dep'
        },
        { text: 'Inscrits', value: 'count' }
      ]
    }
  },
  methods: {
    getColor(d) {
      return d > 110
        ? 'var(--v-primary-base)'
        : d > 90
        ? 'var(--v-primary-lighten1)'
        : d > 60
        ? 'var(--v-primary-lighten2)'
        : d > 30
        ? 'var(--v-primary-lighten3)'
        : d > 15
        ? 'var(--v-primary-lighten4)'
        : d > 0
        ? 'var(--v-primary-lighten5)'
        : 'white'
    }
  }
}
</script>
